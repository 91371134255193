// Here you can add other styles

.ml-1 {
  margin-left: $spacer * 0.25;
}

.ml-2 {
  margin-left: $spacer * 0.5;
}

.ml-3 {
  margin-left: $spacer;
}

.ml-4 {
  margin-left: $spacer * 1.5;
}

.ml-5 {
  margin-left: $spacer * 3;
}

.mr-1 {
  margin-right: $spacer * 0.25;
}

.mr-2 {
  margin-right: $spacer * 0.5;
}

.mr-3 {
  margin-right: $spacer;
}

.mr-4 {
  margin-right: $spacer * 1.5;
}

.mr-5 {
  margin-right: $spacer * 3;
}

.m-0 {
  margin: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

@include media-breakpoint-down(md) {
  .px-0-m {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .hidden-m {
    display: none !important;
  }
}

@import 'cube';

.pagination {
  .page-item:not(.disabled) {
    cursor: pointer;
  }
}

.page-link.active,
.active > .page-link {
  z-index: initial !important;
}

.time-range-menu a.dropdown-item {
  cursor: pointer;
}

.custom-range-select {
  margin: 0;
  .col {
    padding-right: 0.2em;
    padding-left: 0.2em;
  }
}

.form-select-sm {
  height: calc(1.5em + 0.5rem + 2px) !important;
  line-height: 1.2 !important;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em !important;
  opacity: 0.5;
  background-color: #fff;
  &:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #000, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    top: -5px;
    display: inline-block;
    color: black;
    padding: 0 0.5em;
    line-height: 1.5em;
    background-color: #fcfcfa;
  }
}

.invalid {
  .tox {
    border-color: rgb(229, 83, 83) !important;
  }
}

.form-label {
  &.invalid {
    color: rgb(229, 83, 83) !important;
  }
}

.helper-text {
  &.invalid {
    color: rgb(229, 83, 83) !important;
    font-size: 14px;
    &.form {
      margin-top: -10px;
    }
  }
}

.hide-mobile {
  display: none;
}

.margin-top-mobile {
  margin-top: 1em;
}

@include media-breakpoint-up(lg) {
  .hide-mobile {
    display: block;
  }

  .margin-top-mobile {
    margin-top: 0;
  }
}

.organisation-select {
  min-width: 200px;
}

.tox {
  &.tox-tinymce-aux {
    z-index: 2030 !important;
  }
}

ol {
  padding-left: 1em;
}

.basic-multi-select {
  .select__menu {
    z-index: 4 !important;
  }
}

a {
  cursor: pointer;
}

.justify-center {
  justify-content: center;
}

.navbar-footer {
  background-color: $sidebar-nav-group-bg;
  .navbar-toggler {
    width: 100%;
    text-align: right;
  }
}

.nav-link {
  flex: 0 !important;
}
